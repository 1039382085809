import React from 'react';
import PropTypes from 'prop-types';
import { Slots, Slot } from './styledComponents';

const FabricSelector = ({ fabrics, selectedFabrics, toggleFabric, selectNone }) => (
  <Slots>
    {fabrics.map(fabric =>
      <Slot
        isSelected={selectedFabrics.includes(fabric.slug)}
        onClick={() => toggleFabric(fabric.slug)}
        key={fabric.slug}
      >{fabric.name}</Slot>,
    )}
    <Slot
      isSelected={selectedFabrics.length === 0}
      onClick={() => selectNone()}
    >aucun</Slot>
  </Slots>
);

FabricSelector.propTypes = {
  fabrics: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  selectedFabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleFabric: PropTypes.func.isRequired,
  selectNone: PropTypes.func.isRequired,
};

export default FabricSelector;
