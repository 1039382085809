import React from 'react';
import FabricsContainer from '../containers/FabricsContainer';
import routes from '../../../Routes';
import Layout from '../../../layouts/index';

const nextStep = routes.FastVariantLogin.url;
const Step3Fabrics = () => (
  <Layout slug="FastVariantFabrics">
    <FabricsContainer nextStep={nextStep} />;
  </Layout>
);
export default Step3Fabrics;
