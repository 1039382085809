import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';
import FabricSelector from './FabricSelector';
import { Button } from './Home/v2/StyledComponents';
import { Wrapper, Block, Title2, Logo, Question, Center, Header2,
  colors, mobileThresholdPixels, Highlight, Input }
  from './styledComponents';
import LogoImg from '../assets/logo1-128.png';
import ProgressBar from './order/ProgressBar';
import { trackEvent } from '../services/analytics';
import featureSwitch from '../services/featureSwitch';
import { fabrics } from '../core/config';

const P = styled.p`
  color: ${colors.navy};
  text-align: justify;
  padding: 0px 20px;
  font-weight: 300;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    text-align: left;
    font-size: 11px;
  }
`;

const curtainsFabrics = fabrics
  .filter(fabric => fabric.cat === 'curtains')
  .filter(fabric => fabric.slug !== 'curtain');
const clothesFabrics = fabrics.filter(fabric => fabric.cat !== 'curtains');

function getFabrics(showClothesFabrics, showCurtainsFabrics) {
  if (showClothesFabrics && showCurtainsFabrics) {
    return [...curtainsFabrics, ...clothesFabrics];
  } else if (showClothesFabrics) {
    return clothesFabrics;
  }
  return curtainsFabrics;
}

const Step3 = ({ showClothesFabrics, showCurtainsFabrics, selectedFabrics, toggleFabric,
  selectNone, threadColor, setThreadColor, nextStep },
) => (
  <Wrapper>
    <Block>
      <Header2>
        <Link
          to={'/'} style={{ flex: 1, textAlign: 'center' }}
          onClick={() => trackEvent('click', 'home_step3')}
        >
          <Logo src={LogoImg} />
        </Link>
        <ProgressBar step={2} />
        <Title2>
          {featureSwitch('order_thirdPersonLabels') ? 'les matières pour mon client' :
            (<span>mes <Highlight>matières</Highlight></span>)
          }
        </Title2>
      </Header2>
      {featureSwitch('step3_showBasicsNote') &&
        <P>
          {`
          Nos experts fournissent les basiques (boutons, élastiques, doublures, zips, thermocollants)
          dans les coloris classiques. Pour toutes les demandes avec une `}
          <Highlight>forme ou un coloris particulier, merci de vous les fournir</Highlight>
          {' avant votre RDV avec le couturier. Vos questions à l‘adresse : '}<u>contact@tilli.fr</u>
        </P>
      }
      {featureSwitch('order_thirdPersonLabels') ?
        <Question>
          Afin d‘attribuer le couturier le plus adapté aux besoins de votre client,
          merci de préciser si sa commande comporte :
        </Question> :
        <Question noTopPadding center>Pour vous attribuer le couturier le plus adapté :</Question>
      }
      <FabricSelector
        fabrics={getFabrics(showClothesFabrics, showCurtainsFabrics)}
        selectedFabrics={selectedFabrics}
        toggleFabric={toggleFabric}
        selectNone={selectNone}
      />

      {showCurtainsFabrics &&
        <Question noTopPadding center>
          Dernière petite info :
        </Question>
      }
      {showCurtainsFabrics &&
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <Input
            name="threadColor"
            half type="text"
            placeholder={featureSwitch('order_thirdPersonLabels') ?
              'couleur des rideaux' : 'couleur de mes rideaux'}
            value={threadColor}
            onChange={e => setThreadColor(e.target.value)}
          />
        </div>
      }
      <Center><Link to={nextStep}><Button>{'suivant'}</Button></Link></Center>
    </Block>
  </Wrapper>
);

Step3.propTypes = {
  threadColor: PropTypes.string,
  setThreadColor: PropTypes.func,
  showClothesFabrics: PropTypes.bool.isRequired,
  showCurtainsFabrics: PropTypes.bool.isRequired,
  selectedFabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleFabric: PropTypes.func.isRequired,
  selectNone: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
};

Step3.defaultProps = {
  threadColor: '',
  setThreadColor() {},
};

export default Step3;
